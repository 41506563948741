import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index/Index.vue';
import Main from '@/views/main/Main.vue';
import My from '@/views/my/My.vue';
import Login from '@/views/index/Login.vue';
import Task from '@/views/main/Task.vue';
import Tools from '@/views/tools/Tools.vue';

Vue.use(VueRouter)

const globalRoutes = [];

const indexRoutes = {
  path: '/index',
  component: Index,
  name: 'index',
  redirect: { name: 'login' },
  children: [
    { path: 'login', name: 'login', component: Login },
    { path: 'register', name: 'register', component: () => import('@/views/index/SetPassword.vue') },
    { path: 'reset', name: 'reset', component: () => import('@/views/index/SetPassword.vue') },
    { path: 'register-mobile', name: 'register-mobile', component: () => import('@/views/index/CheckMobile.vue') },
    { path: 'reset-mobile', name: 'reset-mobile', component: () => import('@/views/index/CheckMobile.vue') },
  ],
};

const mainRoutes = {
  path: '/',
  component: Main,
  name: 'main',
  redirect: { name: 'task' },
  children: [
    { path: 'task', name: 'task', component: Task },
    { path: 'order', name: 'order', component: () => import('@/views/main/Order.vue') },
    { path: 'account', name: 'account', component: () => import('@/views/main/Account.vue') },
    { path: 'my', name: 'my-nav', component: () => import('@/views/main/MyNav.vue') },
    { path: 'tools', name: 'tools-nav', component: () => import('@/views/main/ToolsNav.vue') },
  ],
  beforeEnter(to, from, next) {
    if (to.path == '/tools') {
      next();
    } else {
      let token = window.localStorage.getItem('token');
      if (token === null || token.length !== 32) {
        window.localStorage.removeItem('token');
        next({ name: 'login' });
      }
      next();
    }
  },
};

const myRoutes = {
  path: '/my',
  component: My,
  name: 'my',
  children: [
    { path: 'user-info', name: 'user-info', component: () => import('@/views/my/UserInfo.vue') },
    { path: 'amount', name: 'amount', component: () => import('@/views/my/Amount.vue') },
    { path: 'script-config', name: 'script-config', component: () => import('@/views/my/ScriptConfig.vue') },
    { path: 'recharge', name: 'recharge', component: () => import('@/views/my/Recharge.vue') },
    { path: 'free-account', name: 'free-account', component: () => import('@/views/my/FreeAccount.vue') },
    { path: 'ph-price', name: 'ph-price', component: () => import('@/views/my/PhPrice.vue') },
    { path: 'server-goods', name: 'server-goods', component: () => import('@/views/my/ServerGoods.vue') },
    { path: 'role-goods', name: 'role-goods', component: () => import('@/views/my/RoleGoods.vue') },
    { path: 'invite-record', name: 'invite-record', component: () => import('@/views/my/InviteRecord.vue') },
    { path: 'device-grant', name: 'device-grant', component: () => import('@/views/my/DeviceGrant.vue') },
    { path: 'mobile-device', name: 'mobile-device', component: () => import('@/views/my/MobileDevice.vue') },
    { path: 'spring-festival', name: 'spring-festival', component: () => import('@/views/my/SpringFestival.vue') },
  ],
  beforeEnter(to, from, next) {
    let token = window.localStorage.getItem('token');
    if (token === null || token.length !== 32) {
      window.localStorage.removeItem('token');
      next({ name: 'login' });
    }
    next();
  },
};

const toolsRoutes = {
  path: '/tools',
  component: Tools,
  name: 'tools',
  children: [
    { path: 'fee-price', name: 'fee-price', component: () => import('@/views/tools/FeePrice.vue') },
    { path: 'ph-needs', name: 'ph-needs', component: () => import('@/views/tools/PhNeeds.vue') },
    { path: 'public-monster', name: 'public-monster', component: () => import('@/views/tools/PublicMonster.vue') },
    { path: 'xinfa-calc', name: 'xinfa-calc', component: () => import('@/views/tools/XinfaCalc.vue') },
    { path: 'horse-calc', name: 'horse-calc', component: () => import('@/views/tools/HorseCalc.vue') },
    { path: 'tianfu-calc', name: 'tianfu-calc', component: () => import('@/views/tools/TianfuCalc.vue') },
    { path: 'role-calc', name: 'role-calc', component: () => import('@/views/tools/RoleCalc.vue') },
    { path: 'st-hot', name: 'st-hot', component: () => import('@/views/tools/StHot.vue') },
  ],
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: globalRoutes.concat(mainRoutes, indexRoutes, toolsRoutes, myRoutes)
})

export default router
